import React, { ReactElement } from 'react';
import { Button, ButtonProps, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

type To = {
  pathname: string;
  state: {
    [key: string]: unknown;
  };
};

interface ILinkButtonProps extends ButtonProps {
  children: ReactElement | string;
  icon?: ReactElement;
  to: string | To;
}

export const LinkButton: React.FC<ILinkButtonProps> = ({
  children,
  icon,
  to,
  ...rest
}) => (
  <ChakraLink
    as={Link}
    to={to}
    _hover={{
      textDecoration: 'none',
    }}
  >
    <Button
      size="sm"
      fontSize="sm"
      colorScheme="blue"
      leftIcon={icon}
      {...rest}
    >
      {children}
    </Button>
  </ChakraLink>
);
