import api from '../api';
import { UserBase } from '../../models/user';

interface IListUserProps {
  name?: string;
  sort?: 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export type IUserListItem = UserBase;

export interface IUserList {
  items: IUserListItem[];
  pages: number;
  total: number;
}

export const listUsersService = async (
  filters?: IListUserProps,
): Promise<IUserList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          name: filters.name || undefined,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await api.get<IUserList>('/users', { params });

  return data;
};
