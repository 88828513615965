import paymentHubApi from '../../apis/paymentHubApi';
import { IClientBase } from '../../models/client';
import { ISellerBase } from '../../models/seller';

export interface ISellerListItem extends ISellerBase {
  client: IClientBase;
}

export const listSellersService = async (): Promise<ISellerListItem[]> => {
  const { data } = await paymentHubApi.get<ISellerListItem[]>('/v1/sellers');

  return data;
};
