import api from '../api';
import { UserBase, UserRoleBase } from '../../models/user';

interface IUpdateUserAvatarProps {
  userId: string;
  avatarData: FormData;
}

export interface IUpdatedUserAvatar extends UserBase {
  userRoles: UserRoleBase[];
}

export const updateUserAvatarsService = async ({
  avatarData,
  userId,
}: IUpdateUserAvatarProps): Promise<IUpdatedUserAvatar> => {
  const { data } = await api.patch<IUpdatedUserAvatar>(
    `/avatars/users/${userId}`,
    avatarData,
  );

  return data;
};
