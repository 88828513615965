import api from '../api';
import {
  RecipientBankAccountBase,
  RecipientBase,
} from '../../models/recipient';
import { AppBase } from '../../models/app';

interface IUpdateRecipientProps {
  recipientId: string;
  commission?: number;
}

export interface IUpdatedRecipient extends RecipientBase {
  app: AppBase;
  bankAccount: RecipientBankAccountBase;
}

export const updateRecipientSettingsService = async ({
  recipientId,
  commission,
}: IUpdateRecipientProps): Promise<IUpdatedRecipient> => {
  const { data } = await api.put<IUpdatedRecipient>(
    `/recipients/${recipientId}/settings`,
    { commission },
  );

  return data;
};
