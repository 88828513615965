import {
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputFieldProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError } from 'react-hook-form';

interface IInputProps extends NumberInputFieldProps {
  name: string;
  label?: string;
  error?: FieldError;
  precision?: number;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (
  { name, label, precision, error = null, ...rest },
  ref,
) => (
  <FormControl isInvalid={!!error}>
    {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

    <ChakraNumberInput precision={precision}>
      <NumberInputField
        ref={ref}
        name={name}
        id={name}
        borderColor="gray.300"
        bg="gray.100"
        focusBorderColor="blue.300"
        variant="outline"
        {...rest}
      />
    </ChakraNumberInput>

    {!!error && (
      <FormErrorMessage position="absolute">{error.message}</FormErrorMessage>
    )}
  </FormControl>
);

export const NumberInput = forwardRef(InputBase);
