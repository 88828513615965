import { useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Text,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedSeller,
  showSellersService,
} from '../../../../../services/PaymentHub/services/Sellers/ShowSellersService';

interface ISeller extends IDetailedSeller {
  formattedDate: string;
}

interface ILocationState {
  sellerId: string;
}

export const SellerDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { sellerId },
  } = useLocation<ILocationState>();

  const [seller, setSeller] = useState<ISeller>();

  useEffect(() => {
    async function loadSeller(): Promise<void> {
      try {
        const sellerData = await showSellersService(sellerId);

        const formattedSeller = {
          ...sellerData,
          formattedDate: maskDate(sellerData.createdAt),
        };

        setSeller(formattedSeller);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os detalhes do seller, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadSeller();
  }, [sellerId, toast]);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do seller
          </Heading>
        </Flex>

        {seller && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {seller.name}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="12"
              w="100%"
            >
              <Text>
                App:
                <Text as="span" color="gray.500" ml="2">
                  {seller.client.name}
                </Text>
              </Text>

              <Text>
                Documento:
                <Text as="span" color="gray.500" ml="2">
                  {seller.cnpj}
                </Text>
              </Text>

              <Text>
                E-mail:
                <Text as="span" color="gray.500" ml="2">
                  {seller.email}
                </Text>
              </Text>
            </SimpleGrid>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
