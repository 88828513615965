import api from '../api';
import { UserBase, UserRoleBase } from '../../models/user';

export interface IDetailedUser extends UserBase {
  userRoles: UserRoleBase[];
}

export const showUsersService = async (
  userId: string,
): Promise<IDetailedUser> => {
  const { data } = await api.get<IDetailedUser>(`/users/${userId}`);

  return data;
};
