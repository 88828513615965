import api from '../api';
import { UserBase, UserRoleBase } from '../../models/user';

interface IUpdateUserProfileProps {
  bio?: string;
  email?: string;
  name?: string;
  password?: string;
  passwordConfirmation?: string;
  phone?: string;
  userId: string;
}

export interface IUpdatedUser extends UserBase {
  userRoles: UserRoleBase[];
}

export const updateUserProfilesService = async ({
  userId,
  bio,
  email,
  name,
  password,
  passwordConfirmation,
  phone,
}: IUpdateUserProfileProps): Promise<IUpdatedUser> => {
  const { data } = await api.patch<IUpdatedUser>(`/users/${userId}/profile`, {
    bio,
    email,
    name,
    password,
    passwordConfirmation,
    phone,
  });

  return data;
};
