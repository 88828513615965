import {
  Box,
  HStack,
  Select,
  Text,
  Input,
  BoxProps,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';

type Option = {
  label: string;
  value: string;
};

interface IOnFilterProps {
  filter: string;
  value: string;
}

interface ITableFiltersProps extends BoxProps {
  filterOptions?: Option[];
  orderingOptions?: Option[];
  onFilter?: (data: IOnFilterProps) => void;
  currentStatus?: string;
  defaultFilterBy?: string;
  defaultSortBy?: string;
  defaultOrder?: 'ASC' | 'DESC';
  onSearch?: (searchValue: string) => void;
  onSort?: (sort: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
}

export const TableFilters = ({
  filterOptions,
  orderingOptions,
  onFilter,
  currentStatus,
  defaultFilterBy,
  defaultSortBy,
  defaultOrder = 'ASC',
  onSearch,
  onSort,
  onOrder,
  ...rest
}: ITableFiltersProps): JSX.Element => {
  const [sortBy, setSortBy] = useState(
    orderingOptions?.find((opt) => opt.value === defaultSortBy)?.value,
  );
  const [orderBy, setOrderBy] = useState(defaultOrder);
  const [filterBy, setFilterBy] = useState(
    filterOptions?.find((opt) => opt.value === defaultFilterBy)?.value,
  );

  const statusSelectOption = useMemo(
    () => [
      { label: 'Todos', value: '' },
      { label: 'Aguardando atendimento', value: 'waiting' },
      { label: 'Pré-atendimento', value: 'preService' },
      { label: 'Em atendimento', value: 'ongoing' },
      { label: 'Visita agendada', value: 'scheduledVisit' },
      { label: 'Visita realizada', value: 'visited' },
      { label: 'Proposta', value: 'proposal' },
      { label: 'Negociação', value: 'negotiation' },
      { label: 'Finalizado', value: 'deal' },
    ],
    [],
  );

  const customerStatusSelectOption = useMemo(
    () => [
      { label: 'Todos', value: '' },
      { label: 'Ativos', value: 'active' },
      { label: 'Inativos', value: 'inactive' },
    ],
    [],
  );

  const orderOptions = useMemo(
    () => [
      { label: 'Crescente', value: 'ASC' },
      { label: 'Decrescente', value: 'DESC' },
    ],
    [],
  );

  const handleFilterChange = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      if (onFilter && filterBy) {
        onFilter({
          filter: filterBy,
          value,
        });
      }
    },
    [filterBy, onFilter],
  );

  const handleSortList = useCallback(
    (value: string) => {
      setSortBy(value);
      setOrderBy('ASC');

      if (onSort) {
        onSort(value);
      }
    },
    [onSort],
  );

  const handleOrderList = useCallback(
    (value: 'ASC' | 'DESC') => {
      setOrderBy(value);

      if (onOrder) {
        onOrder(value);
      }
    },
    [onOrder],
  );

  return (
    <Box {...rest}>
      <HStack spacing="4" align="flex-end">
        {onSearch && (
          <Box>
            <Text>Buscar por nome, cpf ou telefone</Text>
            <Input
              w="96"
              borderColor="gray.300"
              bg="gray.100"
              focusBorderColor="blue.300"
              size="sm"
              variant="outline"
              onChange={(e) => onSearch(e.target.value)}
            />
          </Box>
        )}

        {filterOptions && !!onFilter && (
          <Box>
            <Text>Filtrar por</Text>
            <InputGroup size="sm">
              <InputLeftAddon p="0">
                <Select
                  variant="outline"
                  borderColor="gray.300"
                  focusBorderColor="blue.300"
                  size="sm"
                  value={filterBy}
                  onChange={(e) => handleFilterChange(e.target.value)}
                >
                  {filterOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              </InputLeftAddon>

              {filterBy !== 'status' && filterBy !== 'customerStatus' && (
                <Input
                  w="96"
                  borderColor="gray.300"
                  borderLeftWidth={0}
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  onChange={(e) => handleFilter(e.target.value)}
                />
              )}

              {filterBy === 'status' && (
                <Select
                  w="96"
                  borderColor="gray.300"
                  borderLeftWidth={0}
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  value={
                    statusSelectOption.find(
                      (opt) => opt.value === currentStatus,
                    )?.value
                  }
                  onChange={(e) => handleFilter(e.target.value)}
                >
                  {statusSelectOption.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              )}

              {filterBy === 'customerStatus' && (
                <Select
                  w="96"
                  borderColor="gray.300"
                  borderLeftWidth={0}
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  onChange={(e) => handleFilter(e.target.value)}
                >
                  {customerStatusSelectOption.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              )}
            </InputGroup>
          </Box>
        )}

        {orderingOptions && !!onSort && (
          <Box>
            <HStack>
              <Box>
                <Text>Ordenar por:</Text>
                <Select
                  borderColor="gray.300"
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  size="sm"
                  value={sortBy}
                  onChange={(e) => handleSortList(e.target.value)}
                >
                  {orderingOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              </Box>

              <Box>
                <Text>Ordenação:</Text>
                <Select
                  borderColor="gray.300"
                  bg="gray.100"
                  focusBorderColor="blue.300"
                  variant="outline"
                  size="sm"
                  value={orderBy}
                  onChange={(e) =>
                    handleOrderList(e.target.value as 'ASC' | 'DESC')
                  }
                >
                  {orderOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Select>
              </Box>
            </HStack>
          </Box>
        )}
      </HStack>
    </Box>
  );
};
