import { Box, Heading, Flex, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { SellerTable, ISeller } from './components/SellerTable';
import { listSellersService } from '../../../../../services/PaymentHub/services/Sellers/ListSellersService';

export const SellerList = (): JSX.Element => {
  const toast = useToast();

  const [sellersList, setSellersList] = useState<ISeller[]>([]);

  const loadSellers = useCallback(async () => {
    try {
      const sellers = await listSellersService();

      const formattedSellers = sellers.map((seller) => ({
        ...seller,
        formattedDate: maskShortDate(seller.createdAt),
      }));

      setSellersList(formattedSellers);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar os dados dos sellers, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [toast]);

  useEffect(() => {
    loadSellers();
  }, [loadSellers]);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justifyContent="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Sellers
          </Heading>
        </Flex>

        <SellerTable
          mt="4"
          sellers={sellersList}
          defaultFilterBy="name"
          defaultSortBy="createdAt"
        />
      </Box>
    </DefaultLayout>
  );
};
