import { Box, Heading, Flex, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import {
  maskDateTime,
  maskMoney,
} from '../../../../../utils/formatters/handleMask';
import { listOrdersService } from '../../../../../services/Orders/ListOrdersService';
import { IOrder, OrderTable } from './components/OrderTable';

interface IFilter {
  filter: string;
  value: string;
}

interface ILoadOrdersProps {
  appName?: string;
  code?: string;
  recipientName?: string;
  limit?: number;
  name?: string;
  order?: 'ASC' | 'DESC';
  page?: number;
  phone?: string;
  sort?: 'createdAt';
}

export const OrderList = (): JSX.Element => {
  const toast = useToast();

  const [ordersList, setOrdersList] = useState<IOrder[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentSort, setCurrentSort] = useState<'createdAt'>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const loadOrders = useCallback(
    async ({
      appName,
      code,
      recipientName,
      phone,
      page,
      sort = 'createdAt',
      order = 'ASC',
    }: ILoadOrdersProps) => {
      try {
        const orders = await listOrdersService({
          appName: appName || undefined,
          code: code || undefined,
          recipientName: recipientName || undefined,
          phone: phone || undefined,
          page,
          sort,
          order,
          limit: 20,
        });

        const ordersWithformattedDate = orders.items.map((orderListItem) => ({
          ...orderListItem,
          formattedAmount: maskMoney(orderListItem.amount),
          formattedClosedDate:
            orderListItem.closedAt && maskDateTime(orderListItem.closedAt),
          formattedDate: maskDateTime(orderListItem.createdAt),
        }));

        setTotalPages(orders.pages);
        setOrdersList(ordersWithformattedDate);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos pedidos, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleFilter = useCallback(
    ({ filter, value }: IFilter) => {
      setCurrentFilters({ [filter]: value });

      loadOrders({ [filter]: value });
    },
    [loadOrders],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'createdAt') => {
    setCurrentSort(sort);
  }, []);

  const handleOrderList = useCallback(
    (order: 'ASC' | 'DESC') => {
      loadOrders({
        ...currentFilters,
        page: currentPage,
        sort: currentSort,
        order,
      });
    },
    [currentFilters, currentPage, currentSort, loadOrders],
  );

  useEffect(() => {
    loadOrders({
      ...currentFilters,
      page: currentPage,
      sort: currentSort,
    });
  }, [currentFilters, currentPage, currentSort, loadOrders]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Pedidos
          </Heading>
        </Flex>

        <OrderTable
          mt="4"
          orders={ordersList}
          onFilter={debounceHandleFilter}
          defaultFilterBy="recipientName"
          defaultSortBy="createdAt"
          onSort={handleSortList}
          onOrder={handleOrderList}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
