import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  ButtonGroup,
  HStack,
  Divider,
  Heading,
} from '@chakra-ui/react';

import { MaskedInput } from '../../../../../../components/Form/MaskedInput';
import { ReactSelect } from '../../../../../../components/Form/ReactSelect';

type BankAccountRequest = {
  accountCheckDigit: string;
  accountNumber: string;
  bank: string;
  branchCheckDigit?: string;
  branchNumber: string;
  holderDocument: string;
  holderName: string;
  holderType: 'individual' | 'company';
  metadata?: {
    [key: string]: string;
  };
  type: 'checking' | 'savings';
};

export type AppSplitRecipientData = {
  bankAccount: BankAccountRequest;
  description?: string;
  document: string;
  email: string;
  name: string;
  type: 'individual' | 'company';
};

type AppSplitRecipientFormData = {
  accountCheckDigit: string;
  accountNumber: string;
  accountType: 'checking' | 'savings';
  bank: string;
  branchCheckDigit?: string;
  branchNumber: string;
  description?: string;
  document: string;
  email: string;
  holderDocument: string;
  holderName: string;
  holderType: 'individual' | 'company';
  name: string;
  recipientType: 'individual' | 'company';
};

interface ISplitRecipientProps {
  splitRecipient?: AppSplitRecipientData;
  onSubmit: (splitRecipientData: AppSplitRecipientData) => void;
}

const splitRecipientRegisterFormSchema = Yup.object().shape({
  accountCheckDigit: Yup.string().required('Requerido'),
  accountNumber: Yup.string().required('Requerido'),
  accountType: Yup.string().required('Requerido'),
  bank: Yup.string().required('Requerido'),
  branchCheckDigit: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  branchNumber: Yup.string().required('Requerido'),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  document: Yup.string().required('Requerido'),
  email: Yup.string().required('Requerido'),
  holderDocument: Yup.string().required('Requerido'),
  holderName: Yup.string().required('Requerido'),
  holderType: Yup.string().required('Requerido'),
  name: Yup.string().required('Requerido'),
  recipientType: Yup.string().required('Requerido'),
});

export const AppSplitRecipientForm = ({
  splitRecipient,
  onSubmit,
}: ISplitRecipientProps): JSX.Element => {
  const { goBack } = useHistory();

  const accountTypeOptions = useMemo(
    () => [
      { label: 'Conta Corrente', value: 'checking' },
      { label: 'Conta Poupança', value: 'savings' },
    ],
    [],
  );

  const recipientTypeOptions = useMemo(
    () => [
      { label: 'Pessoa Física', value: 'individual' },
      { label: 'Pessoa Jurídica', value: 'company' },
    ],
    [],
  );

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(splitRecipientRegisterFormSchema),
  });

  useEffect(() => {
    if (splitRecipient) {
      const { bankAccount, ...rest } = splitRecipient;

      reset({
        ...rest,
        ...bankAccount,
        accountType: bankAccount.type,
        recipientType: rest.type,
      });
    }
  }, [reset, splitRecipient]);

  const { errors } = formState;

  const handleNewSplitRecipient: SubmitHandler<AppSplitRecipientFormData> =
    useCallback(
      ({
        accountCheckDigit,
        accountNumber,
        accountType,
        bank,
        branchCheckDigit,
        branchNumber,
        description,
        document,
        email,
        holderDocument,
        holderName,
        holderType,
        name,
        recipientType,
      }) => {
        onSubmit({
          document,
          email,
          name,
          type: recipientType,
          description,
          bankAccount: {
            accountCheckDigit,
            accountNumber,
            bank,
            branchCheckDigit,
            branchNumber,
            holderDocument,
            holderName,
            holderType,
            type: accountType,
          },
        });
      },
      [onSubmit],
    );

  return (
    <Box as="form" onSubmit={handleSubmit(handleNewSplitRecipient)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput label="Nome" error={errors.name} {...register('name')} />

          <MaskedInput
            label="E-mail"
            error={errors.email}
            {...register('email')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput
            label="Documento"
            error={errors.document}
            {...register('document')}
          />

          <ReactSelect
            label="Tipo do recebedor"
            name="recipientType"
            control={control}
            options={recipientTypeOptions}
            error={errors.recipientType}
          />
        </SimpleGrid>

        <MaskedInput
          as="textarea"
          minHeight="100px"
          resize="none"
          py="2"
          label="Descrição"
          error={errors.description}
          {...register('description')}
        />
      </VStack>

      <Heading mt="8" size="md" fontWeight="normal">
        Conta de recebimento
      </Heading>

      <Divider my="6" />

      <VStack spacing="8" align="left">
        <MaskedInput
          label="Titular"
          error={errors.holderName}
          {...register('holderName')}
        />

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4">
            <MaskedInput
              label="Documento"
              error={errors.holderDocument}
              {...register('holderDocument')}
            />

            <ReactSelect
              label="Tipo do titular"
              name="holderType"
              control={control}
              options={recipientTypeOptions}
              error={errors.holderType}
            />
          </HStack>

          <HStack spacing="4">
            <ReactSelect
              label="Tipo da conta"
              name="accountType"
              control={control}
              options={accountTypeOptions}
              error={errors.accountType}
            />

            <MaskedInput
              label="Número do banco"
              error={errors.bank}
              {...register('bank')}
            />
          </HStack>
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <HStack spacing="4">
            <MaskedInput
              label="Número da agência"
              error={errors.branchNumber}
              {...register('branchNumber')}
            />

            <MaskedInput
              label="Dígito"
              error={errors.branchCheckDigit}
              {...register('branchCheckDigit')}
            />
          </HStack>

          <HStack spacing="4">
            <MaskedInput
              label="Número da conta"
              error={errors.accountNumber}
              {...register('accountNumber')}
            />
            <MaskedInput
              label="Dígito"
              error={errors.accountCheckDigit}
              {...register('accountCheckDigit')}
            />
          </HStack>
        </SimpleGrid>
      </VStack>

      <Flex mt="12" justify="flex-end">
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={goBack}>
            Cancelar
          </Button>
          <Button
            type="submit"
            colorScheme="green"
            isLoading={formState.isSubmitting}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
