import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import { FieldError } from 'react-hook-form';

interface ISwitchProps extends ChakraSwitchProps {
  name: string;
  direction?: 'column' | 'row';
  label?: string;
  error?: FieldError;
}

const SwitchBase: ForwardRefRenderFunction<HTMLInputElement, ISwitchProps> = (
  { name, direction = 'column', label, error = null, ...rest },
  ref,
) => (
  <FormControl isInvalid={!!error}>
    <Flex direction={direction}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <ChakraSwitch ref={ref} name={name} id={name} {...rest} />
    </Flex>

    {!!error && (
      <FormErrorMessage position="absolute">{error.message}</FormErrorMessage>
    )}
  </FormControl>
);

export const Switch = forwardRef(SwitchBase);
