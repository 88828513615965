import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  ButtonGroup,
  Icon,
} from '@chakra-ui/react';
import { FiLogIn } from 'react-icons/fi';
import { MaskedInput } from '../../../../../../components/Form/MaskedInput';
import { Switch } from '../../../../../../components/Form/Switch';

export type RegisterAppFormData = {
  description?: string;
  hookUrl?: string;
  name: string;
  paymentProviderPublicKey: string;
  paymentProviderSecretKey: string;
  ref: string;
  storeId?: string;
  usePagarmeProvider: boolean;
};
interface IAppProps {
  app?: RegisterAppFormData;
  onSubmit: (appData: RegisterAppFormData) => void;
}

const appRegisterFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Requerido'),
  paymentProviderPublicKey: Yup.string().required('Requerido'),
  paymentProviderSecretKey: Yup.string().required('Requerido'),
  ref: Yup.string().required('Requerido'),
  storeId: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  usePagarmeProvider: Yup.boolean().default(true),
  hookUrl: Yup.string()
    .url('Inválido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const RegisterAppForm = ({ app, onSubmit }: IAppProps): JSX.Element => {
  const { goBack } = useHistory();

  const { register, handleSubmit, formState, reset, watch } = useForm({
    resolver: yupResolver(appRegisterFormSchema),
  });

  const { errors } = formState;

  const usePagarmeProvider = watch('usePagarmeProvider');

  useEffect(() => {
    reset({
      ...app,
      usePagarmeProvider: true,
    });
  }, [app, reset]);

  const handleNewApp: SubmitHandler<RegisterAppFormData> = useCallback(
    (appData) => {
      onSubmit(appData);
    },
    [onSubmit],
  );

  return (
    <Box as="form" onSubmit={handleSubmit(handleNewApp)}>
      <VStack spacing="8">
        <MaskedInput label="Nome" error={errors.name} {...register('name')} />

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput
            label="Referência"
            autoComplete="off"
            textTransform="uppercase"
            error={errors.ref}
            {...register('ref')}
          />

          <MaskedInput
            label="API Hook Url"
            autoComplete="off"
            type="hookUrl"
            error={errors.hookUrl}
            {...register('hookUrl')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput
            label="Chave pública do provedor de pagamentos"
            autoComplete="off"
            error={errors.paymentProviderPublicKey}
            {...register('paymentProviderPublicKey')}
          />

          <MaskedInput
            label="Chave secreta do provedor de pagamentos"
            autoComplete="off"
            error={errors.paymentProviderSecretKey}
            {...register('paymentProviderSecretKey')}
          />

          <MaskedInput
            label="Store id"
            error={errors.storeId}
            {...register('storeId')}
          />
        </SimpleGrid>

        <MaskedInput
          as="textarea"
          minHeight="100px"
          resize="none"
          py="2"
          label="Descrição"
          error={errors.description}
          {...register('description')}
        />

        <Flex>
          <Switch
            label="Use Pagar.me"
            error={errors.usePagarmeProvider}
            direction="row"
            {...register('usePagarmeProvider')}
          />
        </Flex>
      </VStack>

      <Flex mt="12" justify="flex-end">
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={goBack}>
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme="green"
            isLoading={formState.isSubmitting}
          >
            {usePagarmeProvider ? 'Cadastrar recebedor' : 'Salvar'}
            <Icon as={FiLogIn} fontSize="20" ml="2" />
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
