import { useLocation } from 'react-router-dom';
import { Box, Heading, Flex, useToast, Divider } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedOrder,
  showOrdersService,
} from '../../../../../services/Orders/ShowOrdersService';

interface IOrder extends IDetailedOrder {
  formattedDate: string;
}

interface ILocationState {
  orderId: string;
}

export const OrderDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { orderId },
  } = useLocation<ILocationState>();

  const [order, setOrder] = useState<IOrder>();

  useEffect(() => {
    async function loadOrder(): Promise<void> {
      try {
        const orderData = await showOrdersService(orderId);

        const orderWithformattedDate = {
          ...orderData,
          formattedDate: maskDate(orderData.createdAt),
        };

        setOrder(orderWithformattedDate);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os detalhes do pedidos, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadOrder();
  }, [orderId, toast]);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do pedido
          </Heading>
        </Flex>

        {order && (
          <Flex direction="column">
            <Heading mt="8" size="md" fontWeight="normal">
              Recebedor
            </Heading>

            <Divider my="6" />

            <Heading mt="8" size="md" fontWeight="normal">
              Cliente
            </Heading>

            <Divider my="6" />
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
