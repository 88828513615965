import {
  Box,
  Heading,
  Flex,
  Text,
  SimpleGrid,
  useToast,
  Divider,
  Select,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  IDetailedClient,
  showClientsService,
} from '../../../../services/PaymentHub/services/Clients/ShowClientsService';
import { DefaultLayout } from '../../_layout/DefaultLayout';
import { StatementTabs } from './StatementTabs';
import { listClientsService } from '../../../../services/PaymentHub/services/Clients/ListClientsService';

export const Statements = (): JSX.Element => {
  const toast = useToast();

  const [client, setClient] = useState<IDetailedClient>();
  const [clientSelectOptions, setClientSelectOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    async function listClients(): Promise<void> {
      try {
        const clients = await listClientsService();

        setClientSelectOptions(
          clients.map((clientData) => ({
            label: clientData.tradeName,
            value: clientData.id,
          })),
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os clients, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    listClients();
  }, [toast]);

  async function loadClient(clientId: string): Promise<void> {
    try {
      const clientData = await showClientsService(clientId);

      setClient(clientData);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar os detalhes do client, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Extrato do client
          </Heading>
        </Flex>

        <Select
          w="96"
          borderColor="gray.300"
          borderLeftWidth={0}
          bg="gray.100"
          focusBorderColor="blue.300"
          variant="outline"
          onChange={(e) => loadClient(e.target.value)}
        >
          <option value="">Selecione uma opção</option>
          {clientSelectOptions.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>

        {client && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {client.tradeName}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {client.name}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="12"
              w="100%"
            >
              <Text>
                Ref:
                <Text as="span" color="gray.500" ml="2">
                  {client.ref}
                </Text>
              </Text>

              <Text>
                CNPJ:
                <Text as="span" color="gray.500" ml="2">
                  {client.cnpj}
                </Text>
              </Text>

              <Text>
                Url:
                <Text as="span" color="gray.500" ml="2">
                  {client.hookUrl}
                </Text>
              </Text>
            </SimpleGrid>

            <Divider my="6" borderColor="gray.300" />

            <StatementTabs
              clientPaymentProviderId={
                client.paymentProviders.find(
                  (paymentProvider) => paymentProvider.name === 'GETNET',
                )?.id
              }
            />
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
