import {
  Box,
  Heading,
  Flex,
  Text,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { DefaultLayout } from '../../../_layout/DefaultLayout';

interface ILocationState {
  summary?: Record<string, string | number | boolean>;
  details?: Array<Record<string, string | number | boolean>>;
}

export const StatementDetails = (): JSX.Element => {
  const {
    state: { summary, details },
  } = useLocation<ILocationState>();

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Extrato detalhado
          </Heading>
        </Flex>

        <Flex direction="column">
          {summary && (
            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="12"
              w="100%"
            >
              {Object.entries(summary).map(([key, value]) => (
                <Text key={key}>
                  {`${key}:`}
                  <Text as="span" color="gray.500" ml="2">
                    {value}
                  </Text>
                </Text>
              ))}
            </SimpleGrid>
          )}

          {!!details?.length && (
            <>
              <Divider my="6" borderColor="gray.300" />

              <Heading size="md" fontWeight="normal">
                Detalhes
              </Heading>

              <Flex mt="12" direction="column">
                {details.map((detail, index) => (
                  <Box key={uuid()}>
                    {Object.entries(detail).map(([key, value]) => (
                      <Text key={key}>
                        {`${key}:`}
                        <Text as="span" color="gray.500" ml="2">
                          {value}
                        </Text>
                      </Text>
                    ))}

                    {index + 1 < details.length && (
                      <Divider my="6" borderColor="gray.300" />
                    )}
                  </Box>
                ))}
              </Flex>
            </>
          )}
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
