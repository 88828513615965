import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import {
  IListSellerStatementsResponseContent,
  IChargeback,
} from '../../../../../../../services/PaymentHub/services/Sellers/dtos/IListSellerStatementsResponseContent';

export type IStatement = IListSellerStatementsResponseContent;

interface IStatementChargebackTableProps extends BoxProps {
  chargebacks?: IChargeback[];
}

export const StatementChargebackTable = ({
  chargebacks = [],
  ...rest
}: IStatementChargebackTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Código do pedido</Th>
          <Th>Valor</Th>
          <Th>Data</Th>
          <Th w="8" />
        </Tr>
      </Thead>

      <Tbody>
        {chargebacks.map((chargeback) => (
          <Tr key={chargeback.adjustmentId}>
            <Td>
              <Text fontWeight="bold">{chargeback.orderId}</Text>
            </Td>

            <Td>{chargeback.adjustmentAmount}</Td>

            <Td>{chargeback.adjustmentDate}</Td>

            <Td>
              <LinkButton
                icon={<Icon as={RiPencilLine} fontSize="16" />}
                to={{
                  pathname: '/statements/transactions/details',
                  state: {
                    summary: chargeback,
                  },
                }}
              >
                Detalhes
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
