import {
  Box,
  Heading,
  Flex,
  ButtonGroup,
  Button,
  Link,
  useToast,
  Icon,
  HStack,
  Text,
  VStack,
  SimpleGrid,
  Divider,
  InputGroup,
  InputLeftAddon,
  Select,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { subDays } from 'date-fns';
import { endOfDay, startOfDay } from 'date-fns/esm';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RiDownload2Line, RiEraserLine, RiFilterLine } from 'react-icons/ri';
import * as Yup from 'yup';
import {
  AsyncSelect,
  SelectOption,
} from '../../../../../components/Form/AsyncSelect';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { listAppsService } from '../../../../../services/Apps/ListAppsService';
import { exportOrdersInvoiceReportsService } from '../../../../../services/Orders/ExportOrdersInvoiceReportsService';
import { listOrdersInvoiceReportsService } from '../../../../../services/Orders/ListOrdersInvoiceReportsService';
import { listRecipientsService } from '../../../../../services/Recipients/ListRecipientsService';
import {
  maskCnpj,
  maskDateTime,
  maskMoney,
} from '../../../../../utils/formatters/handleMask';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import {
  IInvoiceReportOrder,
  InvoiceReportTable,
} from './components/InvoiceReportTable';

interface ILoadOrdersProps {
  appId?: string;
  code?: string;
  endDate?: Date;
  page?: number;
  recipientId?: string;
  startDate?: Date;
}

interface IExportInvoiceReportsFormData {
  appId?: string;
  endDate?: Date;
  code?: string;
  recipientId?: string;
  recipientFilterBy?: 'name' | 'tradingName';
  startDate?: Date;
}

const invoiceReportsFormSchema = Yup.object().shape({
  appId: Yup.string()
    .nullable()
    .uuid()
    .transform((value, originalValue) =>
      originalValue === '' ? null : value.value,
    ),
  code: Yup.string().nullable(),
  endDate: Yup.date()
    .max(
      endOfDay(subDays(new Date(), 7)),
      'Data posterior à data máxima permitida.',
    )
    .nullable(),
  recipientId: Yup.string()
    .nullable()
    .transform((value, originalValue) =>
      originalValue === '' ? null : value.value,
    ),
  recipientFilterBy: Yup.string().oneOf(['name, tradingName']),
  startDate: Yup.date()
    .max(Yup.ref('endDate'), 'Data de início deve ser anterior à data final')
    .nullable(),
});

export const InvoiceReportList = (): JSX.Element => {
  const toast = useToast();

  const [ordersList, setOrdersList] = useState<IInvoiceReportOrder[]>([]);
  const [defaultAppSelectOptions, setDefaultAppSelectOptions] =
    useState<SelectOption[]>();
  const [defaultRecipientSelectOptions, setDefaultRecipientSelectOptions] =
    useState<SelectOption[]>();
  const [downloadLinkUrl, setDownloadLinkUrl] = useState<string>();
  const [isFiltering, setIsFiltering] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [recipientFilterBy, setRecipientFilterBy] = useState<
    'name' | 'tradingName'
  >('tradingName');

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const maxDate = useMemo(() => subDays(new Date(), 7), []);

  const { register, handleSubmit, formState, reset, setValue, control } =
    useForm({
      resolver: yupResolver(invoiceReportsFormSchema),
    });

  const { errors } = formState;

  const recipientFilterByOptions = useMemo(
    () => [
      {
        label: 'Nome fantasia',
        value: 'tradingName',
      },
      {
        label: 'Recebedor',
        value: 'name',
      },
    ],
    [],
  );

  const loadOrders = useCallback(
    async ({
      appId,
      endDate,
      code,
      page,
      recipientId,
      startDate,
    }: ILoadOrdersProps) => {
      try {
        const orders = await listOrdersInvoiceReportsService({
          appId: appId || undefined,
          endDate: endDate || undefined,
          code: code || undefined,
          recipientId: recipientId || undefined,
          startDate: startDate || undefined,
          page,
        });

        const parsedOrdersInvoiceReports = orders.items.map(
          (orderListItem) => ({
            ...orderListItem,
            formattedCommissionAmount: maskMoney(
              orderListItem.commissionAmount,
            ),
            formattedCreatedAt: maskDateTime(orderListItem.createdAt),
            formattedRecipientDocument: maskCnpj(
              orderListItem.recipientDocument,
            ),
            formattedTotalAmount: maskMoney(orderListItem.totalAmount),
          }),
        );

        setTotalPages(orders.pages);
        setOrdersList(parsedOrdersInvoiceReports);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos pedidos, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadOrders({
      page: currentPage,
    });
  }, [currentPage, loadOrders]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    setValue('endDate', maxDate);
  }, [maxDate, setValue]);

  useEffect(() => {
    if (downloadLinkUrl) {
      downloadLinkRef.current?.click();
    }
  }, [downloadLinkUrl]);

  const handleExportInvoiceReports: SubmitHandler<IExportInvoiceReportsFormData> =
    useCallback(
      async ({ appId, code, endDate, recipientId, startDate }) => {
        try {
          const fileUrl = await exportOrdersInvoiceReportsService({
            appId,
            code,
            endDate: endDate ? endOfDay(endDate) : undefined,
            recipientId,
            startDate: startDate ? startOfDay(startDate) : undefined,
          });

          setDownloadLinkUrl(fileUrl);

          toast({
            title: 'Exportado com sucesso',
            description: 'Relatório foi exportado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao exportar',
              description:
                'Ocorreu um erro ao exportar o relatório, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [toast],
    );

  const handleRecipientFilterBy = useCallback(
    (value: 'name' | 'tradingName') => {
      setRecipientFilterBy(value);
    },
    [],
  );

  const handleLoadAppSelectOption = useCallback(
    async (name?: string): Promise<SelectOption[]> => {
      const { items: apps } = await listAppsService({
        name,
        limit: 4,
      });

      const parsedSellersSelectOption: SelectOption[] = [
        {
          label: 'Todos',
          value: null,
        },
        ...apps.map((app) => ({
          label: app.name,
          value: app.id,
        })),
      ];

      return parsedSellersSelectOption;
    },
    [],
  );

  const debounceHandleLoadAppSelectOption = debounce(
    handleLoadAppSelectOption,
    500,
  );

  const handleLoadRecipientSelectOption = useCallback(
    async (value?: string): Promise<SelectOption[]> => {
      const { items: recipients } = await listRecipientsService({
        name: recipientFilterBy === 'name' ? value : undefined,
        tradingName: recipientFilterBy === 'tradingName' ? value : undefined,
        limit: 4,
      });

      const parsedSellersSelectOption: SelectOption[] = [
        {
          label: 'Todos',
          value: null,
        },
        ...recipients.map((recipient) => ({
          label: recipient[recipientFilterBy] || '-',
          value: recipient.paymentProviderRecipientId,
        })),
      ];

      return parsedSellersSelectOption;
    },
    [recipientFilterBy],
  );

  const debounceHandleLoadRecipientSelectOption = debounce(
    handleLoadRecipientSelectOption,
    500,
  );

  useEffect(() => {
    async function loadAppsDefaultOptions(): Promise<void> {
      const appOptions = await handleLoadAppSelectOption();

      setDefaultAppSelectOptions(appOptions);
    }

    async function loadRecipientsDefaultOptions(): Promise<void> {
      const recipientOptions = await handleLoadRecipientSelectOption();

      setDefaultRecipientSelectOptions(recipientOptions);
    }

    loadAppsDefaultOptions();
    loadRecipientsDefaultOptions();
  }, [handleLoadAppSelectOption, handleLoadRecipientSelectOption, reset]);

  const handleFilter: SubmitHandler<IExportInvoiceReportsFormData> =
    useCallback(
      async ({ appId, code, endDate, recipientId, startDate }) => {
        setIsFiltering(true);

        setCurrentPage(1);

        await loadOrders({ appId, code, endDate, recipientId, startDate });
      },
      [loadOrders],
    );

  const handleCleanFilters = useCallback(async () => {
    setCurrentPage(1);

    await loadOrders({});

    setRecipientFilterBy('tradingName');

    reset({
      appId: {
        label: 'Todos',
        value: null,
      },
      code: null,
      endDate: maxDate,
      recipientId: {
        label: 'Todos',
        value: null,
      },
      startDate: null,
    });

    setIsFiltering(false);
  }, [loadOrders, maxDate, reset]);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Relatórios
          </Heading>
        </Flex>

        <Flex
          as="form"
          flexDirection="column"
          onSubmit={handleSubmit(handleFilter)}
        >
          <ButtonGroup alignSelf="flex-end">
            <Button
              disabled={!isFiltering}
              colorScheme="blue"
              size="sm"
              onClick={handleCleanFilters}
            >
              <HStack alignItems="center">
                <Icon as={RiEraserLine} fontSize="md" />
                <Text>Limpar filtros</Text>
              </HStack>
            </Button>

            <Button colorScheme="blue" size="sm" type="submit">
              <HStack alignItems="center">
                <Icon as={RiFilterLine} fontSize="md" />
                <Text>Filtrar</Text>
              </HStack>
            </Button>

            <Link
              hidden
              download={`jhsf-pay-invoice-reports-${Date.now()}.xlsx`}
              ref={downloadLinkRef}
              href={downloadLinkUrl}
            >
              Download
            </Link>
            <Button
              colorScheme="green"
              size="sm"
              onClick={handleSubmit(handleExportInvoiceReports)}
            >
              <HStack>
                <Icon as={RiDownload2Line} fontSize="md" />
                <Text>Exportar</Text>
              </HStack>
            </Button>
          </ButtonGroup>

          <VStack spacing="8">
            <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
              <AsyncSelect
                label="App"
                name="appId"
                defaultOptions={defaultAppSelectOptions}
                defaultValue={{
                  label: 'Todos',
                  value: null,
                }}
                loadOptions={debounceHandleLoadAppSelectOption}
                control={control}
                error={errors.appId}
              />

              <FormControl>
                <FormLabel htmlFor="recipientFilter">Loja</FormLabel>
                <InputGroup name="recipientFilter" size="lg">
                  <InputLeftAddon p={0} border="0px transparent">
                    <Select
                      bg="gray.100"
                      borderColor="gray.300"
                      focusBorderColor="blue.300"
                      borderRightWidth={0}
                      borderRightRadius={0}
                      variant="outline"
                      size="lg"
                      value={recipientFilterBy}
                      onChange={(e) =>
                        handleRecipientFilterBy(
                          e.target.value as 'name' | 'tradingName',
                        )
                      }
                    >
                      {recipientFilterByOptions.map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </Select>
                  </InputLeftAddon>

                  <AsyncSelect
                    name="recipientId"
                    defaultOptions={defaultRecipientSelectOptions}
                    defaultValue={{
                      label: 'Todos',
                      value: null,
                    }}
                    inputStyles={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    loadOptions={debounceHandleLoadRecipientSelectOption}
                    control={control}
                    error={errors.recipientId}
                  />
                </InputGroup>
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
              <MaskedInput
                label="Código do pedido"
                error={errors.code}
                {...register('code')}
              />

              <DatePicker
                label="Data de abertura de"
                isClearable
                maxDate={maxDate}
                control={control}
                error={errors.startDate}
                {...register('startDate')}
              />

              <DatePicker
                label="Data de abertura até"
                maxDate={maxDate}
                control={control}
                error={errors.endDate}
                {...register('endDate')}
              />
            </SimpleGrid>

            <Divider />
          </VStack>
        </Flex>

        <InvoiceReportTable
          mt="4"
          orders={ordersList}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
