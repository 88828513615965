import { AppBase } from '../../models/app';
import { OrderBase } from '../../models/order';
import api from '../api';

export interface IDetailedOrder extends OrderBase {
  app: AppBase;
}

export const showOrdersService = async (
  orderId: string,
): Promise<IDetailedOrder> => {
  const { data } = await api.get<IDetailedOrder>(`/orders/${orderId}`);

  return data;
};
