import api from '../api';

interface IExportOrdersInvoiceReportsProps {
  appId?: string;
  code?: string;
  endDate?: Date;
  recipientId?: string;
  startDate?: Date;
}

export const exportOrdersInvoiceReportsService = async (
  filters?: IExportOrdersInvoiceReportsProps,
): Promise<string> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          appId: filters.appId || undefined,
          code: filters.code || undefined,
          endDate: filters.endDate,
          recipientId: filters.recipientId || undefined,
          startDate: filters.startDate,
        }
      : undefined;

  const { data } = await api.post<Blob>('/reports/orders', params, {
    responseType: 'blob',
  });

  const fileUrl = URL.createObjectURL(data);

  return fileUrl;
};
