import { AppBase } from '../../models/app';
import {
  RecipientBankAccountBase,
  RecipientBase,
} from '../../models/recipient';
import api from '../api';

export interface IDetailedRecipient extends RecipientBase {
  app: AppBase;
  bankAccount: RecipientBankAccountBase;
}

export const showRecipientsService = async (
  recipientId: string,
): Promise<IDetailedRecipient> => {
  const { data } = await api.get<IDetailedRecipient>(
    `/recipients/${recipientId}`,
  );

  return data;
};
