import { UserRoleBase } from '../../models/user';
import api from '../api';

export type IUserRoleList = UserRoleBase[];

export const listUserRolesService = async (): Promise<IUserRoleList> => {
  const { data } = await api.get<IUserRoleList>('/users/roles');

  return data;
};
