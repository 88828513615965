import api from '../api';

interface IResetPasswordProps {
  password: string;
  passwordConfirmation: string;
  token: string;
}

export const resetPasswordsService = async ({
  password,
  passwordConfirmation,
  token,
}: IResetPasswordProps): Promise<void> => {
  await api.post('/passwords/reset', { password, passwordConfirmation, token });
};
