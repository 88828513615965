import { OrderInvoiceReportBase } from '../../models/order';
import api from '../api';

export type IOrderInvoiceReportListItem = OrderInvoiceReportBase;

interface IListOrdersInvoiceReportsResponse {
  items: OrderInvoiceReportBase[];
  total: number;
  pages: number;
}

interface IListOrdersInvoiceReportsProps {
  appId?: string;
  code?: string;
  endDate?: Date;
  page?: number;
  recipientId?: string;
  startDate?: Date;
}

export const listOrdersInvoiceReportsService = async (
  filters?: IListOrdersInvoiceReportsProps,
): Promise<IListOrdersInvoiceReportsResponse> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          appId: filters.appId || undefined,
          code: filters.code || undefined,
          endDate: filters.endDate,
          page: filters.page,
          recipientId: filters.recipientId || undefined,
          startDate: filters.startDate,
        }
      : undefined;

  const { data } = await api.get<IListOrdersInvoiceReportsResponse>(
    '/reports/orders',
    {
      params,
    },
  );

  return data;
};
