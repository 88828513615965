import api from '../api';
import {
  AppBase,
  AppSplitRecipientBankAccountBase,
  AppSplitRecipientBase,
} from '../../models/app';
import { RecipientBase } from '../../models/recipient';

interface IDetailedAppSplitRecipient extends AppSplitRecipientBase {
  bankAccount: AppSplitRecipientBankAccountBase;
}

export interface IDetailedApp extends AppBase {
  recipients: RecipientBase[];
  splitRecipient: IDetailedAppSplitRecipient;
}

export const showAppsService = async (appId: string): Promise<IDetailedApp> => {
  const { data } = await api.get<IDetailedApp>(`/apps/${appId}`);

  return data;
};
