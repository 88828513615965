import paymentHubApi from '../../apis/paymentHubApi';
import { IClientBase } from '../../models/client';

export const listClientsService = async (): Promise<IClientBase[]> => {
  const { data } = await paymentHubApi.get<{ items: IClientBase[] }>(
    '/v1/clients',
  );

  return data.items;
};
