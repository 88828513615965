import api from '../api';
import { AppBase } from '../../models/app';

interface IListAppProps {
  name?: string;
  limit?: number;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'name' | 'ref' | 'url' | 'createdAt';
}

export type IAppListItem = AppBase;

export interface IAppList {
  items: IAppListItem[];
  pages: number;
  total: number;
}

export const listAppsService = async (
  filters?: IListAppProps,
): Promise<IAppList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          name: filters.name || undefined,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
          sort: filters.sort,
        }
      : undefined;

  const { data } = await api.get<IAppList>('/apps', { params });

  return data;
};
