import api from '../api';
import { CustomerBase } from '../../models/customer';
import { AppBase } from '../../models/app';

interface IListCustomerProps {
  appName?: string;
  email?: string;
  name?: string;
  sort?: 'name' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export interface ICustomerListItem extends CustomerBase {
  app: AppBase;
}

export interface ICustomerList {
  items: ICustomerListItem[];
  pages: number;
  total: number;
}

export const listCustomersService = async (
  filters?: IListCustomerProps,
): Promise<ICustomerList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          appName: filters.appName || undefined,
          email: filters.email || undefined,
          name: filters.name || undefined,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
          sort: filters.sort,
        }
      : undefined;

  const { data } = await api.get<ICustomerList>('/customers', { params });

  return data;
};
