import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import {
  IListSellerStatementsResponseContent,
  ITransaction,
} from '../../../../../../../services/PaymentHub/services/Sellers/dtos/IListSellerStatementsResponseContent';

export type IStatement = IListSellerStatementsResponseContent;

interface IStatementTransactionTableProps extends BoxProps {
  transactions?: ITransaction[];
}

export const StatementTransactionTable = ({
  transactions = [],
  ...rest
}: IStatementTransactionTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>OrderId</Th>
          <Th>Amount</Th>
          <Th>Installments</Th>
          <Th>Status</Th>
          <Th w="8" />
        </Tr>
      </Thead>

      <Tbody>
        {transactions.map((transaction) => (
          <Tr key={transaction.summary.acquirerTransactionId}>
            <Td>
              <Text fontWeight="bold">{transaction.summary.orderId}</Text>
            </Td>

            <Td>{transaction.summary.cardPaymentAmount}</Td>

            <Td>{transaction.summary.numberInstallments}</Td>

            <Td>{transaction.summary.reasonMessage}</Td>

            <Td>
              <LinkButton
                icon={<Icon as={RiPencilLine} fontSize="16" />}
                to={{
                  pathname: '/statements/transactions/details',
                  state: {
                    summary: transaction.summary,
                    details: transaction.details,
                  },
                }}
              >
                Detalhes
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
