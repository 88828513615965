import { HStack, Icon } from '@chakra-ui/react';
import { RiNotificationLine } from 'react-icons/ri';

export const NotificationsNav = (): JSX.Element => (
  <HStack
    spacing={['6', '8']}
    mx={['6', '8']}
    pr={['6', '8']}
    py="1"
    borderRightWidth={1}
    borderColor="gray.700"
  >
    <Icon as={RiNotificationLine} fontSize="24" />
  </HStack>
);
