import {
  FormLabel,
  FormControl,
  FormErrorMessage,
  InputProps,
} from '@chakra-ui/react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { PhoneInput } from './PhoneInput';

interface IInternationalPhoneInputProps extends InputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  label?: string;
  error?: FieldError;
}

export const InternationalPhoneInput = ({
  control,
  isDisabled,
  error,
  label,
  name,
  ...rest
}: IInternationalPhoneInputProps): JSX.Element => (
  <FormControl isInvalid={!!error}>
    {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <PhoneInput
          onChangePhone={onChange}
          onBlur={onBlur}
          value={value}
          isDisabled={isDisabled}
          {...rest}
        />
      )}
    />

    {!!error && (
      <FormErrorMessage position="absolute">{error.message}</FormErrorMessage>
    )}
  </FormControl>
);
