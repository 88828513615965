import paymentHubApi from '../../apis/paymentHubApi';
import { IClientBase, IClientPaymentProviderBase } from '../../models/client';

export interface IDetailedClient extends IClientBase {
  paymentProviders: IClientPaymentProviderBase[];
}

export const showClientsService = async (
  clientId: string,
): Promise<IDetailedClient> => {
  const { data } = await paymentHubApi.get<IDetailedClient>(
    `/v1/clients/${clientId}`,
  );

  return data;
};
