import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import {
  IListSellerStatementsResponseContent,
  IAdjustment,
} from '../../../../../../../services/PaymentHub/services/Sellers/dtos/IListSellerStatementsResponseContent';

export type IStatement = IListSellerStatementsResponseContent;

interface IStatementAdjustmentTableProps extends BoxProps {
  adjustments?: IAdjustment[];
}

export const StatementAdjustmentTable = ({
  adjustments = [],
  ...rest
}: IStatementAdjustmentTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Código do pedido</Th>
          <Th>Valor</Th>
          <Th>Data</Th>
          <Th w="8" />
        </Tr>
      </Thead>

      <Tbody>
        {adjustments.map((adjustment) => (
          <Tr key={adjustment.adjustmentId}>
            <Td>
              <Text fontWeight="bold">{adjustment.orderId}</Text>
            </Td>

            <Td>{adjustment.adjustmentAmount}</Td>

            <Td>{adjustment.adjustmentDate}</Td>

            <Td>
              <LinkButton
                icon={<Icon as={RiPencilLine} fontSize="16" />}
                to={{
                  pathname: '/statements/transactions/details',
                  state: {
                    summary: adjustment,
                  },
                }}
              >
                Detalhes
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
