import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  SimpleGrid,
  useToast,
  ButtonGroup,
  Divider,
} from '@chakra-ui/react';
import { RiDeleteBinLine, RiEditLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedRecipient,
  showRecipientsService,
} from '../../../../../services/Recipients/ShowRecipientsService';
import { deleteRecipientsService } from '../../../../../services/Recipients/DeleteRecipientsService';
import {
  RecipientBankAccountType,
  RecipientType,
} from '../../../../../models/recipient';
import {
  ISettingsModalFormData,
  RecipientSettingsModal,
} from './components/RecipientSettingsModal';

interface IRecipient extends IDetailedRecipient {
  formattedDate: string;
  formattedCommission: number | null;
}

interface ILocationState {
  recipientId: string;
}

export const RecipientDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { recipientId },
  } = useLocation<ILocationState>();
  const { push } = useHistory();

  const [recipient, setRecipient] = useState<IRecipient>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [isRecipientSettingsModalVisible, setIsRecipientSettingsModalVisible] =
    useState(false);

  useEffect(() => {
    async function loadRecipient(): Promise<void> {
      try {
        const recipientData = await showRecipientsService(recipientId);

        const formattedRecipient = {
          ...recipientData,
          formattedDate: maskDate(recipientData.createdAt),
          formattedCommission: (recipientData?.commission || 0) / 100,
        };

        setRecipient(formattedRecipient);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os detalhes do recebedor, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadRecipient();
  }, [recipientId, toast]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleRecipientSettingsModal = useCallback(() => {
    setIsRecipientSettingsModalVisible((prevState) => !prevState);
  }, []);

  const handleChangeRecipientSettings = useCallback(
    ({ commission }: ISettingsModalFormData) => {
      setRecipient(
        (prevState) =>
          prevState && {
            ...prevState,
            commission,
            formattedCommission: commission ? commission / 100 : null,
          },
      );

      handleToggleRecipientSettingsModal();
    },
    [handleToggleRecipientSettingsModal],
  );

  const handleDeleteRecipient = useCallback(async () => {
    try {
      await deleteRecipientsService(recipientId);

      toast({
        title: 'Excluído com sucesso',
        description: 'O recebedor foi excluído corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      push('/recipients');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description:
            'Ocorreu um erro ao excluir o recebedor, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [recipientId, push, toast]);

  return (
    <DefaultLayout>
      <RecipientSettingsModal
        recipientId={recipientId}
        commission={recipient?.commission}
        isOpen={isRecipientSettingsModalVisible}
        onClose={handleToggleRecipientSettingsModal}
        onChangeSettings={handleChangeRecipientSettings}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteRecipient}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do recebedor
          </Heading>

          <ButtonGroup>
            <Button
              size="sm"
              fontSize="sm"
              colorScheme="yellow"
              color="white"
              onClick={handleToggleRecipientSettingsModal}
              leftIcon={<Icon as={RiEditLine} fontSize="20" />}
            >
              Configurar
            </Button>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button>
          </ButtonGroup>
        </Flex>

        {recipient && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {recipient.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {recipient.description}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="12"
              w="100%"
            >
              <Text>
                App:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.app.name}
                </Text>
              </Text>

              <Text>
                Documento:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.document}
                </Text>
              </Text>

              <Text>
                Tipo do recebedor:
                <Text as="span" color="gray.500" ml="2">
                  {RecipientType[recipient.type]}
                </Text>
              </Text>

              <Text>
                E-mail:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.email}
                </Text>
              </Text>

              <Text>
                Comissão:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.formattedCommission
                    ? `${recipient.formattedCommission} %`
                    : 'N/A'}
                </Text>
              </Text>
            </SimpleGrid>

            <Heading mt="8" size="md" fontWeight="normal">
              Conta de recebimento
            </Heading>

            <Divider my="6" />

            <SimpleGrid minChildWidth="240px" spacing="8" px="8" w="100%">
              <Text>
                Titular:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.bankAccount.holderName}
                </Text>
              </Text>

              <Text>
                Banco:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.bankAccount.bank}
                </Text>
              </Text>

              <Text>
                Agência:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.bankAccount.branchNumber}
                </Text>
              </Text>

              <Text>
                Dígito:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.bankAccount.branchCheckDigit}
                </Text>
              </Text>

              <Text>
                Tipo da conta:
                <Text as="span" color="gray.500" ml="2">
                  {RecipientBankAccountType[recipient.bankAccount.type]}
                </Text>
              </Text>

              <Text>
                Conta:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.bankAccount.accountNumber}
                </Text>
              </Text>

              <Text>
                Dígito:
                <Text as="span" color="gray.500" ml="2">
                  {recipient.bankAccount.accountCheckDigit}
                </Text>
              </Text>
            </SimpleGrid>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};
