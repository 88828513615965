import paymentHubApi from '../../apis/paymentHubApi';
import { IClientBase } from '../../models/client';
import { ISellerBase, ISellerPaymentProviderBase } from '../../models/seller';

export interface IDetailedSeller extends ISellerBase {
  client: IClientBase;
  paymentProviders: ISellerPaymentProviderBase[];
}

export const showSellersService = async (
  sellerId: string,
): Promise<IDetailedSeller> => {
  const { data } = await paymentHubApi.get<IDetailedSeller>(
    `/v1/sellers/${sellerId}`,
  );

  return data;
};
