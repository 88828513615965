import api from '../api';
import { OrderBase } from '../../models/order';
import { AppBase } from '../../models/app';
import { RecipientBase } from '../../models/recipient';

interface IListOrderProps {
  appName?: string;
  code?: string;
  recipientName?: string;
  limit?: number;
  name?: string;
  order?: 'ASC' | 'DESC';
  page?: number;
  phone?: string;
  sort?: 'createdAt';
}
export interface IOrderListItem extends OrderBase {
  app: AppBase;
  recipient: RecipientBase;
}

export interface IOrderList {
  items: IOrderListItem[];
  pages: number;
  total: number;
}

export const listOrdersService = async (
  filters?: IListOrderProps,
): Promise<IOrderList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          appName: filters.appName || undefined,
          code: filters.code || undefined,
          recipientName: filters.recipientName || undefined,
          limit: filters.limit,
          name: filters.name || undefined,
          order: filters.order,
          page: filters.page,
          sort: filters.sort,
        }
      : undefined;

  const { data } = await api.get<IOrderList>('/orders', { params });

  return data;
};
