import api from '../api';
import { UserBase, UserRoleBase } from '../../models/user';

interface IUpdateUserProps {
  userId: string;
  bio?: string;
  email: string;
  name: string;
  password?: string;
  passwordConfirmation?: string;
  phone?: string;
  userRoles: UserRoleBase[];
}

export interface IUpdatedUser extends UserBase {
  userRoles: UserRoleBase[];
}

export const updateUsersService = async ({
  userId,
  bio,
  email,
  name,
  password,
  passwordConfirmation,
  phone,
  userRoles,
}: IUpdateUserProps): Promise<IUpdatedUser> => {
  const { data } = await api.patch<IUpdatedUser>(`/users/${userId}`, {
    bio,
    email,
    name,
    password,
    passwordConfirmation,
    phone,
    userRoles,
  });

  return data;
};
