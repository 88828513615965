import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import {
  IListSellerStatementsResponseContent,
  ICommission,
} from '../../../../../../../services/PaymentHub/services/Sellers/dtos/IListSellerStatementsResponseContent';

export type IStatement = IListSellerStatementsResponseContent;

interface IStatementCommissionTableProps extends BoxProps {
  commissions?: ICommission[];
}

export const StatementCommissionTable = ({
  commissions = [],
  ...rest
}: IStatementCommissionTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Seller ID</Th>
          <Th>Comissão</Th>
          <Th>Taxa</Th>
          <Th>Item ID</Th>
          <Th>Data</Th>
          <Th w="8" />
        </Tr>
      </Thead>

      <Tbody>
        {commissions.map((commission) => (
          <Tr key={commission.paymentId}>
            <Td>{commission.marketplaceSubsellerid}</Td>

            <Td>{commission.subsellerRateAmount}</Td>

            <Td>{commission.mdrRateAmmount}</Td>

            <Td>{commission.itemId}</Td>

            <Td>{commission.paymentDate}</Td>

            <Td>
              <LinkButton
                icon={<Icon as={RiPencilLine} fontSize="16" />}
                to={{
                  pathname: '/statements/transactions/details',
                  state: {
                    summary: commission,
                  },
                }}
              >
                Detalhes
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
