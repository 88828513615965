import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import {
  IListSellerStatementsResponseContent,
  IPaymentSummary,
} from '../../../../../../../services/PaymentHub/services/Sellers/dtos/IListSellerStatementsResponseContent';

export type IStatement = IListSellerStatementsResponseContent;

interface IStatementPaymentSummaryTableProps extends BoxProps {
  paymentSummaries?: IPaymentSummary[];
}

export const StatementPaymentSummaryTable = ({
  paymentSummaries = [],
  ...rest
}: IStatementPaymentSummaryTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Seller ID</Th>
          <Th>Valor</Th>
          <Th>Operação</Th>
          <Th w="8" />
        </Tr>
      </Thead>

      <Tbody>
        {paymentSummaries.map((paymentSummary) => (
          <Tr key={paymentSummary.operationIdentifierCode}>
            <Td>{paymentSummary.cpfcnpjSubseller}</Td>

            <Td>{paymentSummary.amountPaid}</Td>

            <Td>{paymentSummary.operationType}</Td>

            <Td>
              <LinkButton
                icon={<Icon as={RiPencilLine} fontSize="16" />}
                to={{
                  pathname: '/statements/transactions/details',
                  state: {
                    summary: paymentSummary,
                  },
                }}
              >
                Detalhes
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
