import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { area, cep, cpf, creci, money, phone, rg } from './masks';

interface IInputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError;
  mask?: 'area' | 'cep' | 'cpf' | 'creci' | 'money' | 'phone' | 'rg';
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (
  { name, label, mask, error = null, ...rest },
  ref,
) => {
  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      switch (mask) {
        case 'area':
          area(e);
          break;

        case 'cep':
          cep(e);
          break;

        case 'cpf':
          cpf(e);
          break;

        case 'creci':
          creci(e);
          break;

        case 'money':
          money(e);
          break;

        case 'phone':
          phone(e);
          break;

        case 'rg':
          rg(e);
          break;

        default:
          break;
      }
    },
    [mask],
  );

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <ChakraInput
        ref={ref}
        name={name}
        id={name}
        onKeyUp={handleInputChange}
        borderColor="gray.300"
        bg="gray.100"
        focusBorderColor="blue.300"
        variant="outline"
        size="lg"
        {...rest}
      />

      {!!error && (
        <FormErrorMessage position="absolute">{error.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const MaskedInput = forwardRef(InputBase);
