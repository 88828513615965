import {
  Box,
  BoxProps,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Input as ChakraInput,
  useToast,
  Text,
  VStack,
  Flex,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import { IListSellerStatementsResponseContent } from '../../../../../services/PaymentHub/services/Sellers/dtos/IListSellerStatementsResponseContent';
import { listStatementsService } from '../../../../../services/PaymentHub/services/Sellers/ListStatementsService';
import { StatementAdjustmentTable } from './components/StatementAdjustmentTable';
import { StatementChargebackTable } from './components/StatementChargebackTable';
import { StatementCommissionTable } from './components/StatementCommissionTable';
import { StatementPaymentSummaryTable } from './components/StatementPaymentSummaryTable';
import { StatementTransactionTable } from './components/StatementTransactionTable';

registerLocale('pt-BR', ptBr);

interface ICurrentFilters {
  confirmationDateEnd?: string;
  confirmationDateInit?: string;
  scheduleDateEnd?: string;
  scheduleDateInit?: string;
  transactionDateEnd?: string;
  transactionDateInit?: string;
}

interface ILoadStatementsProps {
  acquirerTransactionId?: string;
  clientProviderId: string;
  confirmationDateEnd?: string;
  confirmationDateInit?: string;
  scheduleDateEnd?: string;
  scheduleDateInit?: string;
  transactionDateEnd?: string;
  transactionDateInit?: string;
}

interface IStatementTabsProps extends BoxProps {
  clientPaymentProviderId?: string;
}

export const StatementTabs = ({
  clientPaymentProviderId,
  ...props
}: IStatementTabsProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);

  const [confirmationStartDate, setConfirmationStartDate] =
    useState<Date | null>(null);
  const [confirmationEndDate, setConfirmationEndDate] = useState<Date | null>(
    null,
  );
  const [scheduleStartDate, setScheduleStartDate] = useState<Date | null>(null);
  const [scheduleEndDate, setScheduleEndDate] = useState<Date | null>(null);
  const [transactionStartDate, setTransactionStartDate] = useState<Date | null>(
    null,
  );
  const [transactionEndDate, setTransactionEndDate] = useState<Date | null>(
    null,
  );

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const toast = useToast();

  const [statements, setStatements] =
    useState<IListSellerStatementsResponseContent>();

  const filters = useMemo(() => {
    const currentFilters: ICurrentFilters = {};

    if (confirmationEndDate && confirmationStartDate) {
      currentFilters.confirmationDateEnd = confirmationEndDate
        ? confirmationEndDate.toISOString()
        : undefined;

      currentFilters.confirmationDateInit = confirmationStartDate
        ? confirmationStartDate.toISOString()
        : undefined;
    }

    if (scheduleEndDate && scheduleStartDate) {
      currentFilters.scheduleDateEnd = scheduleEndDate
        ? scheduleEndDate.toISOString()
        : undefined;

      currentFilters.scheduleDateInit = scheduleStartDate
        ? scheduleStartDate.toISOString()
        : undefined;
    }

    if (transactionEndDate && transactionStartDate) {
      currentFilters.transactionDateEnd = transactionEndDate
        ? transactionEndDate.toISOString()
        : undefined;

      currentFilters.transactionDateInit = transactionStartDate
        ? transactionStartDate.toISOString()
        : undefined;
    }

    return currentFilters;
  }, [
    confirmationEndDate,
    confirmationStartDate,
    scheduleEndDate,
    scheduleStartDate,
    transactionEndDate,
    transactionStartDate,
  ]);

  useEffect(() => {
    const loadStatements = async ({
      clientProviderId,
      confirmationDateEnd,
      confirmationDateInit,
      scheduleDateEnd,
      scheduleDateInit,
      transactionDateEnd,
      transactionDateInit,
    }: ILoadStatementsProps): Promise<void> => {
      try {
        const statementsList = await listStatementsService({
          clientProviderId,
          confirmationDateEnd,
          confirmationDateInit,
          scheduleDateEnd,
          scheduleDateInit,
          transactionDateEnd,
          transactionDateInit,
        });

        setStatements(statementsList);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos extratos, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    };

    if (!!clientPaymentProviderId && !!Object.keys(filters).length) {
      loadStatements({
        clientProviderId: clientPaymentProviderId,
        ...filters,
      });
    }
  }, [toast, filters, clientPaymentProviderId]);

  return (
    <Box {...props}>
      <Text fontWeight="bold">Filtros:</Text>

      <HStack mt="4" spacing="6">
        <VStack align="flex-start">
          <Text>Data de confirmação:</Text>

          <Flex w="full" alignItems="center">
            <Text fontSize="sm" mr="2">
              Início:
            </Text>

            <Box ml="auto" w="40">
              <ReactDatePicker
                dateFormat="dd MMM yyyy"
                isClearable
                locale="pt-BR"
                todayButton="Hoje"
                selected={confirmationStartDate}
                onChange={(date: Date) => setConfirmationStartDate(date)}
                selectsStart
                startDate={confirmationStartDate}
                endDate={confirmationEndDate}
                customInput={
                  <ChakraInput
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    size="sm"
                    variant="outline"
                  />
                }
              />
            </Box>
          </Flex>

          <Flex w="full" alignItems="center">
            <Text fontSize="sm" mr="2">
              Fim:
            </Text>

            <Box ml="auto" w="40">
              <ReactDatePicker
                dateFormat="dd MMM yyyy"
                isClearable
                locale="pt-BR"
                todayButton="Hoje"
                selected={confirmationEndDate}
                onChange={(date: Date) => setConfirmationEndDate(date)}
                selectsEnd
                startDate={confirmationStartDate}
                endDate={confirmationEndDate}
                minDate={confirmationStartDate}
                customInput={
                  <ChakraInput
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    size="sm"
                    variant="outline"
                  />
                }
              />
            </Box>
          </Flex>
        </VStack>

        <VStack align="flex-start">
          <Text>Data de agendamento:</Text>

          <Flex w="full" alignItems="center">
            <Text fontSize="sm" mr="2">
              Início:
            </Text>

            <Box ml="auto" w="40">
              <ReactDatePicker
                dateFormat="dd MMM yyyy"
                isClearable
                locale="pt-BR"
                todayButton="Hoje"
                selected={scheduleStartDate}
                onChange={(date: Date) => setScheduleStartDate(date)}
                selectsStart
                startDate={scheduleStartDate}
                endDate={scheduleEndDate}
                customInput={
                  <ChakraInput
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    size="sm"
                    variant="outline"
                  />
                }
              />
            </Box>
          </Flex>

          <Flex w="full" alignItems="center">
            <Text fontSize="sm" mr="2">
              Fim:
            </Text>

            <Box ml="auto" w="40">
              <ReactDatePicker
                dateFormat="dd MMM yyyy"
                isClearable
                locale="pt-BR"
                todayButton="Hoje"
                selected={scheduleEndDate}
                onChange={(date: Date) => setScheduleEndDate(date)}
                selectsEnd
                startDate={scheduleStartDate}
                endDate={scheduleEndDate}
                minDate={scheduleStartDate}
                customInput={
                  <ChakraInput
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    size="sm"
                    variant="outline"
                  />
                }
              />
            </Box>
          </Flex>
        </VStack>

        <VStack align="flex-start">
          <Text>Data da transação:</Text>

          <Flex w="full" alignItems="center">
            <Text fontSize="sm" mr="2">
              Início:
            </Text>

            <Box ml="auto" w="40">
              <ReactDatePicker
                dateFormat="dd MMM yyyy"
                isClearable
                locale="pt-BR"
                todayButton="Hoje"
                selected={transactionStartDate}
                onChange={(date: Date) => setTransactionStartDate(date)}
                selectsStart
                startDate={transactionStartDate}
                endDate={transactionEndDate}
                customInput={
                  <ChakraInput
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    size="sm"
                    variant="outline"
                  />
                }
              />
            </Box>
          </Flex>

          <Flex w="full" alignItems="center">
            <Text fontSize="sm" mr="2">
              Fim:
            </Text>

            <Box ml="auto" w="40">
              <ReactDatePicker
                dateFormat="dd MMM yyyy"
                isClearable
                locale="pt-BR"
                todayButton="Hoje"
                selected={transactionEndDate}
                onChange={(date: Date) => setTransactionEndDate(date)}
                selectsEnd
                startDate={transactionStartDate}
                endDate={transactionEndDate}
                minDate={transactionStartDate}
                customInput={
                  <ChakraInput
                    borderColor="gray.300"
                    bg="gray.100"
                    focusBorderColor="blue.300"
                    size="sm"
                    variant="outline"
                  />
                }
              />
            </Box>
          </Flex>
        </VStack>
      </HStack>

      <Tabs
        index={tabIndex}
        isLazy
        onChange={handleTabChanges}
        variant="enclosed-colored"
        mt="6"
      >
        <TabList>
          <Tab>Transações</Tab>

          <Tab>Comissões</Tab>

          <Tab>Ajustes</Tab>

          <Tab>Estornos</Tab>

          <Tab>Pagamentos</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <StatementTransactionTable
              transactions={statements?.listTransactions}
            />
          </TabPanel>

          <TabPanel>
            <StatementCommissionTable commissions={statements?.commissions} />
          </TabPanel>

          <TabPanel>
            <StatementAdjustmentTable adjustments={statements?.adjustments} />
          </TabPanel>

          <TabPanel>
            <StatementChargebackTable chargebacks={statements?.chargeback} />
          </TabPanel>

          <TabPanel>
            <StatementPaymentSummaryTable
              paymentSummaries={statements?.paymentSummaries}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
