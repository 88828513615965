import api from '../api';
import { UserBase, UserRoleBase } from '../../models/user';

interface ICreateSessionProps {
  email: string;
  password: string;
}

export interface ISessionUser extends UserBase {
  userRoles: UserRoleBase[];
}

export interface INewSession {
  user: ISessionUser;
  token: string;
  refreshToken: string;
}

export const authenticateSessionsService = async ({
  email,
  password,
}: ICreateSessionProps): Promise<INewSession> => {
  const { data } = await api.post<INewSession>('/sessions', {
    email,
    password,
  });

  return data;
};
