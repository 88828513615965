export enum OrderStatus {
  pending = 'Pendente',
  paid = 'Pago',
  canceled = 'Cancelado',
  processing = 'Processando',
  failed = 'Falha',
  overpaid = 'Pago a mais',
  underpaid = 'Pago a menos',
}

export type OrderBase = {
  paymentProviderOrderId: string;
  amount: number;
  closed: boolean;
  code: string;
  currency: string;
  jcoins?: number;
  invoiceIssued: boolean;
  invoiceDate?: string;
  invoiceError?: string;
  status:
    | 'pending'
    | 'paid'
    | 'canceled'
    | 'processing'
    | 'failed'
    | 'overpaid'
    | 'underpaid';
  createdAt: string;
  updatedAt: string;
  closedAt?: string;
};

export type OrderInvoiceReportBase = {
  paymentProviderOrderId: string;
  appName: string;
  appRef: string;
  code: string;
  commission: number;
  commissionAmount: number;
  customerName: string;
  freightAmount: number;
  productsAmount: number;
  recipientAmount: number;
  recipientDocument: string;
  recipientName: string;
  recipientTradingName?: string;
  totalAmount: number;
  closedAt?: string;
  createdAt: string;
};
