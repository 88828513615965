import { FormEvent } from 'react';

export const cep = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 9;
  if (e.currentTarget.value.replace(/\D/g, '').length) {
    e.currentTarget.value = e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/^(\d{5})(\d)/, '$1-$2');
  }

  return e;
};

export const cpf = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 14;
  if (!e.currentTarget.value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    e.currentTarget.value = e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{2})$/, '$1-$2');
  }

  return e;
};

export const rg = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 10;
  if (!e.currentTarget.value.match(/^(\d{2}).(\d{3}).(\d{3})$/)) {
    e.currentTarget.value = e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2');
  }

  return e;
};

export const creci = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 14;
  if (e.currentTarget.value.replace(/\D/g, '').length) {
    e.currentTarget.value = `CRECI-${e.currentTarget.value
      .toUpperCase()
      .replace('CRECI-', '')
      .replace(/(\D{2})(\d{6})/, '$1$2')}`;
  }

  return e;
};

export const money = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 17;
  if (e.currentTarget.value.replace(/\D/g, '').length) {
    e.currentTarget.value = `R$ ${e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.')}`;
  }

  return e;
};

export const area = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 13;
  if (e.currentTarget.value.replace(/\D/g, '').length) {
    e.currentTarget.value = `${e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.')} m²`;
  }

  return e;
};

export const phone = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 15;
  if (e.currentTarget.value.replace(/\D/g, '').length) {
    e.currentTarget.value = e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/^(\d{2})/, '($1) ')
      .replace(/(\d{5})/, '$1-')
      .replace(/(\d{4})/, '$1');
  }

  return e;
};
