import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { RiPencilLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { ISellerListItem } from '../../../../../../../services/PaymentHub/services/Sellers/ListSellersService';

export interface ISeller extends ISellerListItem {
  formattedDate: string;
}

interface IOnFilterProps {
  filter: string;
  value: string;
}

interface ISellerTableProps extends BoxProps {
  sellers: ISeller[];
  onFilter?: (data: IOnFilterProps) => void;
  defaultFilterBy?: string;
  defaultSortBy?: string;
  onSort?: (
    sort: 'name' | 'email' | 'commission' | 'type' | 'createdAt',
  ) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  totalPages?: number;
}

export const SellerTable = ({
  sellers,
  onFilter,
  defaultFilterBy,
  defaultSortBy,
  onSort,
  onOrder,
  currentPage,
  onPageChange,
  totalPages,
  ...rest
}: ISellerTableProps): JSX.Element => {
  const handleSort = useCallback(
    (sort: string) => {
      if (onSort) {
        onSort(sort as 'name' | 'email' | 'commission' | 'type' | 'createdAt');
      }
    },
    [onSort],
  );

  const filterOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'App',
        value: 'appName',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
    ],
    [],
  );

  const orderingOptions = useMemo(
    () => [
      {
        label: 'Data de cadastro',
        value: 'createdAt',
      },
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'Comissão',
        value: 'commission',
      },
      {
        label: 'Tipo',
        value: 'type',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        filterOptions={onFilter ? filterOptions : undefined}
        orderingOptions={orderingOptions}
        onFilter={onFilter}
        defaultFilterBy={defaultFilterBy}
        defaultSortBy={defaultSortBy}
        onSort={handleSort}
        onOrder={onOrder}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Seller</Th>
            <Th>E-mail</Th>
            <Th>Data de cadastro</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {sellers.map((seller) => (
            <Tr key={seller.id}>
              <Td>
                <Box>
                  <Text fontWeight="bold">{seller.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {`App: ${seller.client.name}`}
                  </Text>
                </Box>
              </Td>

              <Td>{seller.email}</Td>

              <Td>{seller.formattedDate}</Td>

              <Td>
                <LinkButton
                  icon={<Icon as={RiPencilLine} fontSize="16" />}
                  to={{
                    pathname: '/sellers/details',
                    state: {
                      sellerId: seller.id,
                    },
                  }}
                >
                  Detalhes
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
