import { AppBase } from '../../models/app';
import { CustomerBase } from '../../models/customer';
import api from '../api';

export interface IDetailedCustomer extends CustomerBase {
  app: AppBase;
}

export const showCustomersService = async (
  customerId: string,
): Promise<IDetailedCustomer> => {
  const { data } = await api.get<IDetailedCustomer>(`/customers/${customerId}`);

  return data;
};
