import { Text } from '@chakra-ui/react';

export const Logo = (): JSX.Element => (
  <Text
    fontSize={['2xl', '3xl']}
    fontWeight="bold"
    letterSpacing="tight"
    textTransform="uppercase"
  >
    jhsf-pay
    <Text as="span" ml="1" color="blue.500">
      .
    </Text>
  </Text>
);
