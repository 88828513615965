import paymentHubApi from '../../apis/paymentHubApi';
import { IListSellerStatementsResponseContent } from './dtos/IListSellerStatementsResponseContent';

interface IListStatementProps {
  acquirerTransactionId?: string;
  clientProviderId: string;
  confirmationDateEnd?: string;
  confirmationDateInit?: string;
  liquidationDateEnd?: string;
  liquidationDateInit?: string;
  marketplaceTransactionId?: number;
  mgmOrderNsu?: number;
  orderId?: string;
  scheduleDateEnd?: string;
  scheduleDateInit?: string;
  subsellerId?: string;
  transactionDateEnd?: string;
  transactionDateInit?: string;
}

export const listStatementsService = async ({
  acquirerTransactionId,
  clientProviderId,
  confirmationDateEnd,
  confirmationDateInit,
  liquidationDateEnd,
  liquidationDateInit,
  marketplaceTransactionId,
  mgmOrderNsu,
  orderId,
  scheduleDateEnd,
  scheduleDateInit,
  subsellerId,
  transactionDateEnd,
  transactionDateInit,
}: IListStatementProps): Promise<IListSellerStatementsResponseContent> => {
  const { data } =
    await paymentHubApi.get<IListSellerStatementsResponseContent>(
      '/v1/sellers/statements',
      {
        params: {
          acquirerTransactionId,
          clientProviderId,
          confirmationDateEnd,
          confirmationDateInit,
          liquidationDateEnd,
          liquidationDateInit,
          marketplaceTransactionId,
          mgmOrderNsu,
          orderId,
          scheduleDateEnd,
          scheduleDateInit,
          subsellerId,
          transactionDateEnd,
          transactionDateInit,
        },
      },
    );

  return data;
};
