import {
  Input as ChakraInput,
  FormLabel,
  FormControl,
  FormErrorMessage,
  InputProps,
} from '@chakra-ui/react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Control, Controller, FieldError } from 'react-hook-form';
import ptBr from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef, ForwardRefRenderFunction } from 'react';

interface IDatePickerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  error?: FieldError;
  inputProps?: InputProps;
  isClearable?: boolean;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
}

registerLocale('pt-BR', ptBr);

const DatePickerBase: ForwardRefRenderFunction<
  HTMLInputElement,
  IDatePickerProps
> = ({
  name,
  label,
  control,
  error,
  inputProps,
  isClearable = false,
  maxDate,
  minDate,
  ...rest
}: IDatePickerProps): JSX.Element => (
  <FormControl isInvalid={!!error} display="flex" flexDirection="column">
    {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <ReactDatePicker
          dateFormat="dd MMM yyyy"
          isClearable={isClearable}
          locale="pt-BR"
          maxDate={maxDate}
          minDate={minDate}
          onBlur={onBlur}
          onChange={onChange}
          selected={value}
          todayButton="Hoje"
          customInput={
            <ChakraInput
              borderColor="gray.300"
              bg="gray.100"
              focusBorderColor="blue.300"
              size="lg"
              variant="outline"
              {...inputProps}
            />
          }
        />
      )}
      {...rest}
    />

    {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
  </FormControl>
);

export const DatePicker = forwardRef(DatePickerBase);
